*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5% !important;
}

body {
	font-family: 'Source Serif Pro', serif;
	font-weight: 400;
	/* font-size: 16px; */
	line-height: 1.7;
	box-sizing: border-box;
	background-color: #fff2ef;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Source Serif Pro', serif;
}

figure {
	margin: 0em 0px;
}

p {
	margin: 0 0 0em;
}
a {
	color: white;
	text-decoration: underline;
}
a:hover {
	color: rgb(235, 235, 235);
	text-decoration: underline;
}

.fixed-wraper {
	max-width: 1600px;
	margin: auto;
}

.section-header {
	width: 100%;
	font-size: 3rem;
	text-align: center;
	padding: 0.5rem 0;
}

.section-header-mobile {
	width: 100%;
	font-size: 3rem;
	text-align: center;
	padding: 0.5rem 0;
}

.section-title {
	/* height: 10vh; */
	width: 100%;
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);
	box-shadow: 2px 0px 25px;
	position: sticky;
	top: 80px;
	z-index: 1;
}
.section-title-text {
	color: white;
	font-size: 3rem;
	padding-left: 10rem;
}

.modal-header {
	font-size: 2.2rem;
	color: #707070;
}

.modal-input-label {
	font-size: 1.8rem;
	margin: 1rem 0rem;
}

.modal-input,
.ui.input > input {
	width: 90%;
	height: 3rem;
	border-radius: 20px;
	border-color: #707070;
	margin: 1rem 0rem;
}
.ui.input.focus > input,
.ui.input > input:focus {
	border-color: #008805;
}

.ui.checkbox {
	font-size: 1.5rem;
	margin: 1rem;
}

.ui.modal > :last-child {
	border-bottom-left-radius: 20px !important;
	border-bottom-right-radius: 20px !important;
}

.ui.modal > .close {
	font-size: 2.5em;
}

/* ///////////////////////////////////////////// NAVIGATION /////////////////////////////////////// */
.navigation {
	height: 80px;
	position: sticky;
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);
	width: 100%;
	top: 0;
	z-index: 2;
}

.navBar-container {
	background-image: url('../src/img/aaa.png');
	height: 80px;
	background-position: center;
	background-size: 37%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}

.navigation-logo {
	width: 18rem;
	margin: 0.8rem 12rem;
	display: inline;
}

.navBar_menu {
	display: inline;
	margin-right: 12rem;
	list-style-type: none;
}

.navBar_menu_list {
	list-style-type: none;
	float: right;
	font-size: 1.8rem;
	color: white !important;
	list-style: none;
	padding: 0 1rem;
}

.navBar_menu_item {
	color: white;
	display: inline-block;
	position: relative;
	text-decoration: none;
	transition-duration: 0.5s;
	padding: 0.8rem 0;
	padding: 0.5rem;
	margin: 1rem;
	border-radius: 20px;
}

.expand-item::after {
	content: '';
	display: inline-block;
	right: -10px;
	top: 50%;
	margin-top: -3px;
	position: absolute;
	width: 0;
	height: 0;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	border-top: 5px solid #ffffff;
	border-bottom: none;
}
.expand-item:hover:after {
	cursor: pointer;
}
.second-nav-expanded-background {
	background-image: url('../src/img/aaa.png');
	width: 100%;
	background-size: 37%;
	padding: 0.8rem;
}
.second-nav-expanded {
	height: 40px;
	position: sticky;
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);
	width: 100%;
	top: 80px;
	z-index: 3;
	transition: all 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
}
.second-nav-expanded ul {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	align-content: center;
	font-size: 1.7rem;
	width: 90%;
}

.navigation a {
	text-decoration: none;
	color: white;
}

.navigation a:hover,
.expand-item:hover a {
	text-decoration: none;
	cursor: pointer;
}
.expand-item:hover {
	cursor: pointer;
}
/* .item>a.active-route{
 background: yellow !important;
} */
.active-route {
	border-bottom: 2px solid white;
}
li {
	display: block;
	transition-duration: 0.4s;
}

.navBar li:hover,
footer li:hover {
	cursor: pointer;
	color: black !important;
}
ul li ul {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	transition: all 0.4s ease;
	left: 0;
	display: none;
}

ul li:hover > ul,
ul li ul:hover {
	visibility: visible;
	opacity: 1;
	background: #4b9120;
	display: block;
	width: max-content;
	cursor: pointer;
	color: black !important;
	border: 2px solid rgb(224, 255, 220);
	border-radius: 7px;
	margin-left: -9px;
}

ul li ul li {
	clear: both;
	width: 100%;
	padding: 10px;
}

ul li ul li:not(:last-child) {
	border-bottom: 1px solid black;
}

ul li ul li:hover {
	background: #7eac61;
	color: black !important;
}

ul ul ul {
	position: absolute;
	top: 0;
	left: 100%;
}

/* ///////////mobile vesion ///////////////////////////////////*/
.navigation-mobile {
	height: 10vh;
	position: sticky;
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);
	/* box-shadow: 2px 0px 25px; */
	width: 100%;
	top: 0;
	z-index: 2;
}

.navBar-container-mobile {
	background-image: url('../src/img/aaa.png');
	height: 10vh;
	background-position: center;
	background-size: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	background-size: 58%;
	background-position: top;
}

.navigation-logo-mobile {
	width: 20rem;
	margin: 0.8rem 5rem;
	display: inline;
}

.sidebar-menu-icon {
	font-size: 55px;
}

.close-sidebar-div {
	text-align: end;
	margin: 3rem 3rem 0rem 0rem;
}

.ui.menu {
	font-size: 4rem;
}

.ui.inverted.menu {
	background: linear-gradient(90deg, #549725 0%, #a5c848 100%);
}

.ui.vertical.inverted.menu .menu .item,
.ui.vertical.inverted.menu .menu .item a:not(.ui) {
	color: rgba(255, 255, 255, 0.9);
}

.ui.vertical.menu .menu .item {
	padding: 0.5em 1.9em;
}
.ui.wide.left.sidebar,
.ui.wide.right.sidebar {
	width: 60vw;
}

.ui.inverted.menu .link.item:active,
.ui.inverted.menu a.item:active {
	background: rgb(253 253 253 / 28%);
}

/* ///////////////////////////////////////////////////////////////////FOOTER///////////////////////////// */
.footer {
	width: 100%;
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);
	box-shadow: 2px 0px 25px;
	margin-top: 20px;
	height: 220px;
}

.footer-flex-container {
	display: flex;
	color: white;
	margin: 0rem 10rem;
	padding: 3rem;
	align-content: center;
	justify-content: space-between;
	align-items: stretch;
}

.footer-flex-first-item {
	width: 45%;
}

.footer-logo {
	width: 18rem;
}

.footer-paragraph {
	font-size: 1.5rem;
}

.footer-btn {
	font-size: 1.6rem;
	width: 100px;
	height: 30px;
	background-color: #a8ca4a;
	border-radius: 20px;
	border: none;
	margin-top: 20px;
	box-shadow: 2px 2px 5px rgb(0 0 0 / 52%);
	font-family: 'Source Serif Pro', serif;
	cursor: pointer;
}

.footer-flex-second-item-flex-container {
	display: flex;
	width: 40%;
	justify-content: space-around;
	align-content: center;
	align-items: flex-start;
	flex-wrap: nowrap;
	flex-direction: row;
}

.footer-flex-second-item-flex-header {
	font-size: 2rem;
}

.footer-flex-second-item-flex-item {
	font-size: 1.5rem;
}
.footer a {
	text-decoration: none;
	color: white;
}

.footer a:hover {
	text-decoration: none;
	color: black;
	cursor: pointer;
}

.footer-ending-text {
	text-align: center;
	font-size: 1.5rem;
	color: #303030;
}

/* ////////////////// mobile version//////////////////////////// */
.footer-mobile {
	width: 100%;
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);
	box-shadow: 2px 0px 25px;
	margin-top: 20px;
	font-size: 2.5rem !important;
}

.footer-logo-mobile {
	width: 20rem;
}

.footer-mobile a {
	text-decoration: none;
	color: white;
}

.footer-mobile a:hover {
	text-decoration: none;
	color: black;
	cursor: pointer;
}
.footer-flex-second-item-flex-container-mobile {
	display: flex;
	justify-content: space-around;
	align-content: center;
	align-items: flex-start;
	flex-wrap: nowrap;
	flex-direction: row;
}

.footer-flex-second-item-flex-header-mobile {
	font-size: 3rem;
}

.footer-flex-second-item-flex-item-mobile {
	font-size: 2.5rem;
	line-height: 2.2;
}
.footer-ending-text-mobile {
	text-align: center;
	font-size: 2.5rem;
	color: #303030;
	padding: 2rem 0rem;
}

/* ///////////////////////////////////////////////HOME PAGE STYLED////////////////////////////////////////////////////////////////////////// */

/* //////////////////////////////////////////// HOME PICS SECTION///////////////////////////////////// */
.home-pics-section {
	padding-bottom: 20px;
	background: linear-gradient(to right, #4a9020 0%, #a8ca4a 100%);
	box-shadow: 2px 0px 25px;
	position: relative;
	text-align: -webkit-center;
}
.home-pics-section-background {
	background-image: url('../src/img/aaa.png');
	background-size: 37%;
	/* background-repeat-y: no-repeat; */
	background-repeat: repeat-x;
}
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	transform: skew(-5deg);
	width: 96%;
}

.container .home-pic-card {
	flex: 1;
	transition: all 1s ease-in-out;
	height: 60vh;
	max-height: 650px;
	position: relative;
}

/* .container .home-pic-card:hover {
	flex-grow: 10;
} */

.container img {
	width: 100%;
	height: 100%;
	object-position: left;
	object-fit: cover;
	transition: all 1s ease-in-out;
}
.container .home-pic-card:not(:nth-child(3)) {
	margin-right: 0.5em;
}

.home-pics-svg {
	width: 7rem !important;
	height: 7rem !important;
	transform: skew(5deg);
	fill: wheat !important;
}

.home-pic-card-text {
	transition: all 1s 0.5s;
	position: absolute;
	text-align: start;
	display: none;
	opacity: 0;
	color: rgb(23, 46, 7);
	top: 50%;
	right: 3rem;
	transform: translate(-3rem, -50%);
	transition: all 3s ease-in-out;
}

.home-pic-card-text h2 {
	font-size: 3rem;
	transform: skew(5deg);
}

.home-pic-card-text button {
	width: 20rem;
	height: 4rem;
	font-size: 1.7rem;
	margin-top: 2rem;
	background-color: #afff7e;
	border-radius: 2rem;
	border: none;
	box-shadow: 1px 3px 6px 0px #212121ab;
	transform: skew(5deg);
}

.home-pic-card-text button:hover {
	background-color: #5ba32f;
	cursor: pointer;
}

/* .home-pic-card:hover .home-pic-card-text {
	opacity: 1;
	display: block;
} */

/* ///////////////////////mobile version/////////////////////////// */

.home-pics-section-mobile {
	background: linear-gradient(to right, #4a9020 0%, #a8ca4a 100%);
	box-shadow: 2px 0px 25px;
	position: relative;
}
.home-pics-section-background-mobile {
	background-image: url('../src/img/aaa.png');
	background-size: 58%;
	/* background-repeat-y: no-repeat; */
	background-repeat: repeat-x;
}
.mobile-home-pic-1 {
	height: 20vh;
	background-image: url('../src/img/Untitled-1.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left;
}
.mobile-home-pic-2 {
	height: 20vh;
	background-image: url('../src/img/Untitled-2_2.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;
}
.mobile-home-pic-3 {
	height: 20vh;
	background-image: url('../src/img/Untitled-3.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left;
}

.home-pics-svg-mobile {
	width: 8rem !important;
	height: 8rem !important;
	margin: 2rem;
}

.home-text-mobile {
	padding: 2rem;
	display: flex;
	align-items: center;
	align-content: center;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-end;
	padding-right: 4rem;
	top: 50%;
	position: relative;
	transform: translate(0, -50%);
}

.left-placed {
	justify-content: flex-start;
	padding-left: 4rem;
}

.home-text-mobile h2 {
	font-size: 3rem;
}

.home-text-mobile button {
	width: 25rem;
	height: 6rem;
	font-size: 2.6rem;
	margin-top: 1rem;
	background-color: #afff7e;
	border-radius: 2rem;
	border: none;
	box-shadow: 1px 3px 6px 0px #212121ab;
}

.home-text-mobile button:hover {
	background-color: #5ba32f;
	cursor: pointer;
}

/* //////////////////////////////////////////////////////WHY US SECTION/////////////////////////////////// */

.whyUsSection-background-img {
	background-image: url('../src/img/background_img_2.png');
	background-position: center;
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
}

/* .why-us-section-grid-item-1{grid-area: edno;}
.why-us-section-grid-item-2{grid-area: dve;}
.why-us-section-grid-item-3{grid-area: tri;}
.why-us-section-grid-item-4{grid-area: chetiri;}
.why-us-section-grid-item-5{grid-area: pet;}
.why-us-section-grid-item-6{grid-area: shest;} */

.why-us-section-grid {
	display: grid;
	margin: 1rem 3rem;
	grid-template-columns: auto auto auto;
	grid-gap: 6rem;
}

.why-us-section-grid-item {
	text-align-last: center;
	align-self: center;
}
.why-us-section-grid-item-2 {
	width: 140%;
	position: relative;
	left: -6rem;
}

.why-us-section-grid-item-4,
.why-us-section-grid-item-5,
.why-us-section-grid-item-6 {
	position: relative;
	top: -2rem;
}

.why-us-section-figure {
	width: 20rem;
}

.people-figure {
	background-image: url(../src/img/groupeOfPeople.png);
	background-size: 80%;
	background-repeat: no-repeat;
	background-position: center;
}

.tehnology-figure {
	background-image: url(../src/img/Group\ 157.png);
	background-size: 73%;
	background-repeat: no-repeat;
	background-position: center;
}

.software-figure {
	background-image: url(../src/img/Group\ 162.png);
	background-size: 66%;
	background-repeat: no-repeat;
	background-position: center;
}

.why-us-section-text {
	font-size: 1.65rem;
	text-align: center;
}

/* /////////////////////////// mobile version//////////////// */

.why-us-section-grid-mobile {
	display: grid;
	margin: 0rem 1rem;
	grid-template-columns: auto auto;
	grid-gap: 1rem;
}

.why-us-section-grid-item-mobile {
	text-align-last: center;
	align-self: center;
}

.why-us-section-figure-mobile {
	width: 22rem;
}

.why-us-section-text-mobile {
	font-size: 2rem;
	text-align: center;
}

/* //////////////////////////////////////////// OUR CLIENTS SECTION - SPLIDE LIBRARY//////////////////// */
.our-clients-section-container {
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);
	width: 100%;
	box-shadow: 2px 0px 25px;
}
.splide-container {
	padding: 3rem 12rem;
}
.splide-top-line {
	height: 3px;
	background: white;
	border: white;
	border-radius: 20px;
	box-shadow: 0px 5px 11px;
}

.splide-bottom-line {
	height: 3px;
	background: white;
	border: white;
	border-radius: 20px;
	box-shadow: 0px -5px 11px;
}

.splide__arrow svg {
	fill: white !important;
}
.splide__slide {
	border-radius: 4px;
	background-size: contain !important;
	filter: grayscale(1);
	cursor: pointer;
}
.splide__slide:hover {
	filter: grayscale(0);
	transform: scale(1.1);
}

.splide__arrow--prev {
	left: 0em !important;
}

.splide__arrow--next {
	right: 0em !important;
}

.splide__pagination__page.is-active {
	background: #cddc39 !important;
}

/* //////////mobile version ///////////////////////// */

.splide-container-mobile {
	padding: 3rem 3rem;
}
.splide-top-line-mobile {
	height: 3px;
	background: white;
	border: white;
	border-radius: 20px;
	box-shadow: 0px 5px 11px;
}

.splide-bottom-line-mobile {
	height: 3px;
	background: white;
	border: white;
	border-radius: 20px;
	box-shadow: 0px -5px 11px;
}

/* ////////////////////////////////////////////////////////NEWS SECTION///////////////////////////// */
.news_section {
	text-align: center;
	text-align: -webkit-center;
}

/* .news_section .cards {
	width: 80%;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: space-around;
	align-items: stretch;
}
.news_section .cards__item {
	display: flex;
	padding: 1rem;
}

@media (min-width: 40rem) {
	.news_section .cards__item {
		width: 50%;
	}
}

@media (min-width: 56rem) {
	.news_section .cards__item {
		width: 33.3333%;
	}
}

.news_section .home-pic-card {
	background-color: white;
	border-radius: 0.25rem;
	box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border: 4px solid #4f9322;
	border-radius: 9px;
	box-shadow: 0px 4px 17px grey;
}

.news_section .card__content {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	padding: 1rem;
}

.news_section .card__title {
	color: #696969;
	font-size: 2rem;
	font-weight: 300;
	text-transform: uppercase;
	text-align: left;
}

.news_section .card__date {
	text-align: left;
	text-align: -webkit-left;
	font-size: 1.4rem;
	color: #4c9121;
}

.news_section .card__text {
	flex: 1 1 auto;
	font-size: 1.4rem;
	line-height: 1.5;
	margin-bottom: 1.25rem;
	text-align: left;
} */

/* //////////////////////////////////////////////////////////////////////////////////////////////CONTACTS PAGE////////////////////// */

.contacts-grid-container {
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
	flex-direction: row;
	display: flex;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.contacts-header-flex {
	display: flex;
	align-items: center;
}

.contacts-header-icon {
	font-size: 4rem;
}

.contacts-header-text {
	font-size: 2rem;
	font-weight: bold;
}

.contacts-text {
	font-size: 1.5rem;
	font-weight: bold;
	color: black;
}

/* ////////////////////////////////////////////////////////////////////ACTUALIZATIONS PAGE/////////////////////////////////////////////////// */

.actualization-warning-box {
	background: #ffffff;
	border: 12px solid #539624;
	border-radius: 20px;
	width: 80%;
	margin: 4rem auto;
}

.actualization-warning-flex {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	flex-direction: row;
	align-content: center;
}

.warning-svg {
	width: 16rem;
	padding: 2rem;
}

.warning-paragraph {
	font-size: 2rem;
	padding: 2rem;
}
.warning-btn-box {
	text-align: right;
}
.warning-btn {
	background: #4a9020;
	box-shadow: 5px 6px 10px #00000029;
	border: 1px solid #4a9020;
	border-radius: 20px;
	color: white;
	font-size: 2rem;
	padding: 0.5rem 2rem;
	margin: 1rem;
}
.warning-btn:hover {
	cursor: pointer;
	background: #295012;
}

/* //////////////////////////////////////////////////////////////////ABOUTUS SECTION/////////////////////////////////////////////////////// */

.aboutUs-grid-item-1 {
	grid-area: a;
}
.aboutUs-grid-item-2 {
	grid-area: b;
}
.aboutUs-grid-item-3 {
	grid-area: v;
}
.aboutUs-grid-item-4 {
	grid-area: c;
}
.aboutUs-grid-item-5 {
	grid-area: d;
}

.aboutUs-section-box {
	width: 80%;
	margin: 3rem auto;
	display: grid;
	grid-template-areas:
		'a a a'
		'b v v'
		'c c d';
	grid-gap: 1rem;
	align-items: center;
	font-size: 2rem;
}
.team_img {
	background-image: url(../src/img/Team.png);
	background-size: 110%;
	background-repeat: no-repeat;
	background-position: center;
}
.logo-small-img {
	background-image: url(../src/img/Logo-green-smal.png);
	background-size: 47%;
	background-repeat: no-repeat;
	background-position: center;
}
/* ///////////////////////////////////////////////////////////MEDICAL SOFTWARE PAGE////////////////////////////////////////////////////////////////////// */

.flex-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-around;
	align-content: center;
	padding: 3rem 5rem;
}
.medical-purpose-pic,
.medical-history-pic {
	height: 30rem;
}
.medical-purpose-text h2,
.medical-history-text h2,
.medical-functionality-text h2,
.medical-extra-functionality-text h2 {
	font-size: 2.5rem;
}
.medical-purpose-text p,
.medical-history-text p,
.medical-functionality-text p,
.medical-extra-functionality-text p {
	font-size: 2rem;
}
.medical-history-container,
.medical-extra-functionality-container {
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);
	width: 100%;
	box-shadow: 2px 0px 25px black;
	color: white;
}
.medical-functionality-pic {
	height: 30rem;
	margin: 3rem;
}
.medilab-benefits-grid-container {
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 1rem;
	padding: 3rem 5rem;
}
.medilab-benefits-grid-container > h3 {
	font-size: 3rem;
}
.medilab-benefits-grid-item {
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 2rem;
	align-items: start;
	justify-content: start;
}
.checkSVG {
	height: 3rem;
	margin-top: 6px;
}

.medilab-benefits-grid-item p {
	font-size: 1.8rem;
}

.med-extra-func-container {
	padding: 0rem 5rem;
	font-size: 2rem !important;
}
.medical-extra-functionality-container ul {
	margin-left: 3rem;
}
.medical-extra-functionality-container ul > li {
	display: list-item;
	list-style-type: '\2714';
	padding-left: 2rem;
}
.med-extra-func-container h3 {
	font-size: 2.2rem !important;
}
.med-extra-func-container-flex {
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 3rem;
	align-items: center;
}
.medical-extra-functionality-pic {
	height: 17rem;
}
.web-portal-figure {
	background-image: url(../src/img/web_profile_img.png);
	background-size: 69%;
	background-repeat: no-repeat;
	background-position: center;
}
.rengen-figure {
	background-image: url(../src/img/PACS_01.png);
	background-size: 95%;
	background-repeat: no-repeat;
	background-position: center;
}
.sofsystems-figure {
	background-image: url(../src/img/softsystems_img.png);
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center;
}

/* MOBILE VERSION////////////////////// */

.mobile-medical-purpose-section,
.mobile-medical-history-section,
.mobile-medical-baseFunctionality-section,
.mobile-extraFunctionality-section {
	text-align: center;
	padding: 3rem;
}

.mobile-medical-purpose-section h2,
.mobile-medical-history-section h2,
.mobile-medical-baseFunctionality-section h2,
.mobile-extraFunctionality-section h2 {
	font-size: 3rem;
}
.mobile-extraFunctionality-section h3 {
	font-size: 2rem;
}

.mobile-medical-purpose-section p,
.mobile-medical-history-section p,
.mobile-medical-baseFunctionality-section p,
.mobile-extraFunctionality-section p {
	text-align: justify;
}
.mobile-medical-purpose-section img,
.mobile-medical-history-section img,
.mobile-medical-baseFunctionality-section img {
	height: 15rem;
}
.mobile-medical-history-section,
.mobile-extraFunctionality-section {
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);

	box-shadow: 2px 0px 25px black;
	color: white;
}
.mobile-medilab-benefits-grid-container {
	display: grid;
	grid-gap: 1rem;
	padding: 1rem 1rem;
}
.mobile-checkSVG {
	height: 3rem !important;
	margin-top: 6px;
}
/* //////////////////////////////////////////// HOTEL SOFTWARE PAGE//////////////////////////// */
.hotel-purpose-pic,
.hotel-history-pic {
	height: 30rem;
}
.hotel-purpose-text h2 {
	font-size: 2.5rem;
}
.hotel-purpose-text p {
	font-size: 2rem;
}
.hotel-purpose-text ul {
	margin-left: 3rem;
}
.hotel-purpose-text ul > li {
	display: list-item;
	list-style-type: '\2714';
	padding-left: 2rem;
}
.ui.menu {
	justify-content: center;
	font-size: 2rem;
	font-family: 'Source Serif Pro', serif;
}
.ui.tabular.menu {
	border-bottom: none !important;
}
.ui.tabular.menu .active.item {
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);
	color: white;
	border-radius: 1.285714rem 1.285714rem 0 0 !important;
	border-color: none !important;
}
.hotel-menu-content-container {
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%) !important;
	width: 100% !important;
	box-shadow: 2px 0px 25px black !important;
	color: white !important;
	border: none !important;
}
.hotel-menu-item-pic {
	height: 30rem;
}
.hotel-menu0item-list-container {
	padding: 0 5rem;
	font-size: 2rem;
}
.hotel-menu0item-list-container h3 {
	font-size: 2.2rem !important;
}
.hotel-menu0item-list-container ul {
	margin-left: 3rem;
}
.hotel-menu0item-list-container ul > li {
	display: list-item;
	list-style-type: '\2714';
	padding-left: 2rem;
}

/*  MOBILE VERSION/////////////////////////////////////// */
.mobile-hotel-purpose-section,
.accordion-content {
	text-align: center;
	padding: 3rem;
}

.mobile-hotel-purpose-section h2,
.accordion-content h2 {
	font-size: 3rem;
}

.mobile-hotel-purpose-section p,
.accordion-content p {
	text-align: justify;
}
.mobile-hotel-purpose-section img,
.accordion-content img {
	height: 20rem;
	text-align: center !important;
}

.mobile-hotel-purpose-section ul,
.accordion-content ul {
	margin-left: 3rem;
}
.mobile-hotel-purpose-section ul > li,
.accordion-content ul > li {
	display: list-item;
	list-style-type: '\2714';
	padding-left: 2rem;
}

.ui.styled.accordion {
	width: 100% !important;
}
.accordion-content h3 {
	font-size: 2rem;
}

.ui.styled.accordion,
.ui.styled.accordion .accordion {
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);
	box-shadow: 2px 0px 25px black;
	color: white;
	font-family: 'Source Serif Pro', serif !important;
}

.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title,
.ui.styled.accordion .accordion .active.title,
.ui.styled.accordion .accordion .title:hover,
.ui.styled.accordion .active.title,
.ui.styled.accordion .title:hover {
	color: rgb(206, 206, 206);
	font-size: 3rem !important;
	font-family: 'Source Serif Pro', serif !important;
}
.ui.styled.accordion .active.title {
	color: white;
	font-family: 'Source Serif Pro', serif !important;
	font-size: 3rem !important;
}
/* .mobile-medical-history-section,
.mobile-extraFunctionality-section {
	background: linear-gradient(90deg, #42811d 0%, #98b842 100%);

	box-shadow: 2px 0px 25px black;
	color: white;
} */
/* //////////////////////////////OTHER PROJECTS PAGE///////////////////////// */

.other-projects-content {
	padding: 3rem 5rem;
}

.other-projects-content p {
	font-size: 2rem;
}
.otherProjects-figure {
	width: 13rem;
}
.otherProjects-grid-item-1 {
	grid-area: A;
}
.otherProjects-grid-item-2 {
	grid-area: B;
}
.otherProjects-grid-item-3 {
	grid-area: C;
}
.otherProjects-grid-item-4 {
	grid-area: D;
}
.otherProjects-grid-item-5 {
	grid-area: E;
}
.otherProjects-grid-item-6 {
	grid-area: F;
}

.other-projects-grid {
	margin: 1rem auto;
	display: grid;
	grid-template-areas:
		'A B B'
		'C C D'
		'E F F';
	align-items: center;
}
.other-projects-pic-1 {
	background-image: url(../src/img/other-projects-pic-1.png);
	background-size: 77%;
	background-repeat: no-repeat;
	background-position: center;
}

.other-projects-pic-2 {
	background-image: url(../src/img/other-projects-pic-2.png);
	background-size: 57%;
	background-repeat: no-repeat;
	background-position: center;
}

.other-projects-pic-3 {
	background-image: url(../src/img/other-projects-pic-3.png);
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center;
}

/* .////////////////////////ADMIN SOFTWARE PAGE////////////////////////////////////// */
.admin-images-flex {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: space-around;
	align-items: center;
	padding: 4rem 0px;
}
.softsystems-link-dark {
	color: black !important;
	font-weight: bold;
}
.softsystems-link-dark:hover {
	color: rgb(99, 99, 99) !important;
}
.fso-img {
	background-image: url(../src/img/softsystems_imgs/diskfso.png);
}

.zapl-img {
	background-image: url(../src/img/softsystems_imgs/diskzapl.png);
}

.fakt-img {
	background-image: url(../src/img/softsystems_imgs/diskfakt.png);
}

.hono-img {
	background-image: url(../src/img/softsystems_imgs/diskhono.png);
}

.sklad-img {
	background-image: url(../src/img/softsystems_imgs/disksklad.png);
}

.fso-img,
.zapl-img,
.fakt-img,
.hono-img,
.sklad-img {
	background-size: 52%;
	background-repeat: no-repeat;
	background-position: center;
}

.admin-images-flex figure:hover {
	transform: scale(1.02);
}

/* ////////////////////////////////////////////MEDIA QUERY/////////////////////////////////////////////////////////////////////////// */

/* ///////MEDIA///////////////
0-600px: Phone
600-900px: Tablet Portrait
900-1200px: Tablet Landscape
1200-1800px: normal
1800px + : Big desktop

75em = 1200px
56.25em=900px

Под 830px мобилна!!!!!!!!!!
*/

@media (max-height: 800px) {
	.navigation-mobile,
	.navBar-container-mobile {
		height: 15vh;
	}
	.ui.menu {
		font-size: 3rem;
	}
	.ui.vertical.menu .menu .item {
		padding: 0.5em 1.4em;
	}
	.ui.wide.left.sidebar,
	.ui.wide.right.sidebar {
		width: 40vw;
	}
	.sidebar-menu-icon {
		font-size: 35px;
	}
	.mobile-home-pic-1,
	.mobile-home-pic-2,
	.mobile-home-pic-3 {
		height: 28vh;
	}
}
@media (max-height: 600px) {
	.navigation-mobile,
	.navBar-container-mobile {
		height: 20vh;
	}
	.mobile-home-pic-1,
	.mobile-home-pic-2,
	.mobile-home-pic-3 {
		height: 33vh;
	}
}
@media (max-height: 500px) {
	.navigation-mobile,
	.navBar-container-mobile {
		height: 25vh;
	}
	.mobile-home-pic-1,
	.mobile-home-pic-2,
	.mobile-home-pic-3 {
		height: 40vh;
	}
	.section-title {
		height: 50px;
	}
}

@media (max-width: 76em) {
	html {
		font-size: 56.25% !important;
	}
	.container .home-pic-card {
		height: 45vh;
	}
	.navBar_menu_item {
		padding: 0.8rem;
	}
	.whyUsSection-background-img {
		background-size: 78%;
	}
}

@media (max-width: 69em) {
	html {
		font-size: 52.25% !important;
	}

	.navBar_menu {
		margin-right: 3rem;
	}

	.navBar_menu_item {
		margin: 0.5rem;
	}

	.container .home-pic-card {
		height: 35vh;
	}

	.why-us-section-grid {
		margin: 1rem 2rem;
		grid-gap: 4rem;
	}
	.why-us-section-figure {
		width: 16rem;
	}
}

@media (max-width: 58em) {
	html {
		font-size: 48% !important;
	}
	.container .home-pic-card {
		height: 30vh;
	}
	.why-us-section-grid {
		margin: 1rem 2rem;
		grid-gap: 5rem;
	}

	.why-us-section-grid-item-4,
	.why-us-section-grid-item-5,
	.why-us-section-grid-item-6 {
		position: relative;
		top: -4rem;
	}
	.why-us-section-text {
		font-size: 1.3rem;
	}
	.second-nav-expanded ul {
		width: 100%;
	}
	.ui.modal > .close {
		top: 3rem;
		right: 3rem;
	}

	.splide__pagination {
		bottom: 0.5rem;
	}
}

@media (max-width: 830px) {
	.warning-btn {
		display: none;
	}
	.ui.menu a {
		text-decoration: none;
	}
	.admin-images-flex {
		display: grid;
		grid-template-columns: auto auto auto;
	}
}

@media (max-width: 599px) {
	.section-header-mobile {
		font-size: 3rem;
	}
	.section-title {
		/* height: 8vh; */
		top: 0px;
	}
	.section-title-text {
		font-size: 3.5rem;
		padding-left: 8rem;
	}
	.navigation-logo-mobile {
		width: 15rem;
		margin: 0.6rem 3rem;
		display: inline;
	}
	.navigation-mobile,
	.navBar-container-mobile {
		height: 10vh;
	}
	.sidebar-menu-icon {
		font-size: 45px;
	}
	.ui.menu {
		font-size: 2.8rem;
	}
	.ui.wide.left.sidebar,
	.ui.wide.right.sidebar {
		width: 50vw;
	}
	.footer-mobile {
		margin-top: 1rem;
		font-size: 1.6rem !important;
	}

	.footer-logo-mobile {
		width: 12rem;
	}

	.footer-flex-second-item-flex-header-mobile {
		font-size: 2rem;
	}

	.footer-flex-second-item-flex-item-mobile {
		font-size: 1.6rem;
		line-height: 1.8;
	}
	.footer-ending-text-mobile {
		font-size: 1.6rem;
	}
	.home-pics-svg-mobile {
		width: 6rem !important;
		height: 6rem !important;
		margin: 1rem;
	}

	.home-text-mobile {
		padding: 1.5rem;
		padding-right: 3rem;
	}

	.left-placed {
		padding-left: 3rem;
	}

	.home-text-mobile h2 {
		font-size: 2.5rem;
	}

	.home-text-mobile button {
		width: 20rem;
		height: 5rem;
		font-size: 2rem;
	}
	figure {
		margin: 0.5em 2rem;
	}
	.why-us-section-grid-mobile {
		margin: 0.5rem 1rem;
		grid-gap: 1rem;
	}

	.why-us-section-figure-mobile {
		width: 15rem;
	}

	.why-us-section-text-mobile {
		font-size: 1.5rem;
	}
	.warning-paragraph {
		font-size: 1.5rem;
		padding: 1.5rem;
	}
	.aboutUs-section-box {
		width: 90%;
		font-size: 1.6rem;
	}
}

@media (max-width: 499px) {
	.section-header-mobile {
		font-size: 2.5rem;
	}
	/* .section-title {
		height: 6vh;
	} */
	.section-title-text {
		font-size: 2.5rem;
		padding-left: 6rem;
	}
	.ui.menu {
		font-size: 2.5rem;
	}
	.ui.wide.left.sidebar,
	.ui.wide.right.sidebar {
		width: 55vw;
	}
	.home-pics-svg-mobile {
		width: 5rem !important;
		height: 5rem !important;
		margin: 0.7rem;
	}

	.home-text-mobile {
		padding: 1rem;
		padding-right: 2rem;
	}

	.left-placed {
		padding-left: 2rem;
	}

	.home-text-mobile h2 {
		font-size: 2rem;
	}

	.home-text-mobile button {
		width: 15rem;
		height: 4rem;
		font-size: 1.5rem;
	}
	.contacts-grid-container {
		display: block;
		position: relative;
		text-align-last: center;
		text-align: center;
	}
	.contacts-header-flex {
		justify-content: center;
		margin-top: 2rem;
	}
	.contacts-header-text {
		font-size: 2.3rem;
	}

	.contacts-text {
		font-size: 2rem;
	}
	.actualization-warning-flex {
		flex-direction: column;
	}
	.warning-btn-box {
		text-align: center;
	}
	.aboutUs-section-box {
		width: 95%;
		font-size: 1.5rem;
	}
	.admin-images-flex {
		display: grid;
		grid-template-columns: auto auto;
	}
}
@media (max-width: 399px) {
	.aboutUs-section-box {
		width: 95%;
		font-size: 1.3rem;
	}
	.why-us-section-figure {
		width: 13rem !important;
	}
	.why-us-section-grid-mobile {
		margin: 0.2rem 0.2rem;
		grid-gap: 0.2rem;
	}

	.why-us-section-figure-mobile {
		width: 10rem;
	}

	.why-us-section-text-mobile {
		font-size: 1.2rem;
	}
}
@media (max-width: 389px) {
	.splide {
		padding: 0.7em 3em !important;
	}
}
@media (max-width: 349px) {
	.ui.menu {
		font-size: 2.2rem;
	}
	.ui.wide.left.sidebar,
	.ui.wide.right.sidebar {
		width: 80vw;
	}
	.why-us-section-grid-mobile {
		margin: 0.1rem 0.1rem;
		grid-gap: 0.1rem;
	}

	.why-us-section-figure-mobile {
		width: 7rem;
	}

	.why-us-section-text-mobile {
		font-size: 1rem;
	}
}
